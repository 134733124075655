import React from "react";
import { Head } from "./Head";

import { AnimatePresence } from "framer-motion";
function Layout(props) {
  return (
    <>
      <Head path={props.location.pathname} />
      <AnimatePresence exitBeforeEnter>{props.children}</AnimatePresence>
    </>
  );
}

export { Layout };
