import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { description } from "./description";
function Head({ path }) {
  const [PathName, setPathName] = useState("Home");
  const [meta, setMeta] = useState({
    desc: "",
    keywords: "",
  });
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  const {
    site: {
      siteMetadata: { title },
    },
  } = data;

  const metaSetter = (desObject) => {
    switch (path) {
      case "/":
        setMeta({
          keywords: desObject.home.keywords,
          desc: desObject.home.desc,
        });
        break;
      case "/services":
        setMeta({
          keywords: desObject.services.keywords,
          desc: desObject.services.desc,
        });
        break;
      case "/gallery":
        setMeta({
          keywords: desObject.gallery.keywords,
          desc: desObject.gallery.desc,
        });
        break;
      case "/route":
        setMeta({
          keywords: desObject.route.keywords,
          desc: desObject.route.desc,
        });
        break;

      default:
        setMeta({
          keywords: desObject.home.keywords,
          desc: desObject.home.desc,
        });
        break;
    }
  };

  useEffect(() => {
    if (path === "/") {
      setPathName("Home");
    } else {
      const p = `${path.slice(1)}`;

      setPathName(p);
    }

    metaSetter(description);
  }, [path]);

  return (
    <>
      <Helmet title={`${title} | ${PathName}`} htmlAttributes={{ lang: "en" }}>
        <meta name="description" content={meta.desc} />
        <meta name="keywords" content={meta.keywords} />
      </Helmet>
    </>
  );
}

export { Head };
