const description = {
  home: {
    desc: "Vaanavil Dental Care home page",
    keywords: "dentist near me, dentist, dental care, dentist tamilNadu",
  },
  services: {
    desc: "Vaanavil Dental Care Services page",
    keywords:
      "dentist near me, child dental care, root canal, laser dentistry, teeth extraction",
  },
  gallery: {
    desc: "Vaanavil Dental Care Gallery page",
    keywords: "dentist near me, dental care",
  },
  route: {
    desc: "Vaanavil Dental Care Route page",
    keywords: "Kader Mohideen Palli Street, vaanavil Dental care",
  },
};

export { description };
